import React from 'react'
import { Form, Input, Checkbox } from 'antd'
import axios from 'axios';


const Search = Input.Search;
const FormItem = Form.Item;

// @ts-ignore
import styles from './signup.module.scss';
import { Link } from 'gatsby';

interface SignupState {
    isChecked: boolean;
    isComplete: boolean;
    value: string;
}

export default class Signup extends React.Component<any, SignupState> {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            isComplete: false,
            value: "",
        };

        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.signup = this.signup.bind(this);
    }

    onChangeCheckBox(e) {
        this.setState({ isChecked: e.target.checked });
    }

    onChangeInput(input) {
        this.setState({ value: input.target.value })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.isChecked) {
            this.signup(this.state.value);
        }
    }

    handleInput() {
        if (this.state.isChecked) {
            this.signup(this.state.value);
        }
    }

    signup(email) {
        axios.post("/api/signup", {email: email}).then(res => {
            if(res.status == 200)
            {
                this.setState({isComplete: true});
            }
        }).catch (err => {
           
        })
    }

    renderForm() {
        if (!this.state.isComplete) {
            return (
                <Form layout="vertical" onSubmit={this.handleSubmit}>
                    <FormItem>
                        <Checkbox onChange={this.onChangeCheckBox} className={styles.infotext}>Jeg samtykker til <Link to="/brukervilkar">vilkårene</Link></Checkbox>
                    </FormItem>
                    <Search className={styles.input}
                        placeholder="Din e-post"
                        enterButton="Enig!"
                        size="large"
                        onChange={value => this.onChangeInput(value)}
                        onSearch={value => this.handleInput()}
                    />
                </Form >
            );
        } else {
            return (<div>Tusen takk!</div>)
        }
    }

    render() {
        return (
            <div style={{}}>
                {this.renderForm()}
            </div>
        );
    }
};