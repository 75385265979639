import React from "react"
import { Button, Layout } from 'antd'
import { Helmet } from "react-helmet"
import './index.css'
import SiteHeader from "../SiteHeader";
import Footer from "../Footer";

// @ts-ignore
import ossLogoSocial from '../../assets/oss-logo-lockup-social.png';

export default ({ children }) => (
    <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Oss</title>
            <link rel="canonical" href="http://oss.no/" />
            <meta name="Description" content="En helt ny, digital tjeneste som gjør at du og dine alltid kan føle dere trygge, hvor enn du er." />
            <meta property="og:url" content="https://oss.no" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Teknologi fra Oss. Trygghet til deg." />
            <meta property="og:description" content="En helt ny, digital tjeneste som gjør at du og dine alltid kan føle dere trygge, hvor enn du er." />
            <meta property="og:image" content={`https://oss.no/${ossLogoSocial}`} />
        </Helmet>
        <SiteHeader />
        <main>
            {children}
        </main>
        <Footer />
    </Layout>
)
